.Post {
    
    position: absolute;
    background: #ffff99;
    box-sizing: border-box;
    touch-action: none;
    border-radius: 0.5rem;
    padding: 1rem;
    border: 2px solid black;
    &:hover,
    &focus {
        cursor: pointer;
        z-index: 999;
        width: 315px;
        height: 315px;
        
    }

    textarea {
        width: 100%;
        height: 95%;
        background: none;
        resize: none;
        padding: 0;
        border: none;
        font-family: monospace;
        font-size: 1.5rem;

        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
    .date {
        margin-bottom: 10px;
        font-size: 1.2rem;        
    }
}